import React, { useState, useEffect } from 'react';
import { getUserWalletAddress } from '../components/Utils';
import Web3 from 'web3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ConnectButton(props) {
  const [walletConnectBtn, setConnectText] = useState("Connect Wallet");
  const [disconnect, setDisconnect] = useState(false);

  const web3 = new Web3(window.ethereum);
  const [wallet, setWallet] = useState(null)
  const userAddress = '';

  // truncate the wallet address
  var truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
  var truncateEthAddress = function (address) {
    var match = address.match(truncateRegex);
      if (!match)
        return address;
    return match[1] + "\u2026" + match[2];
  };

  // DISCONNECT WALLET
  const disconnectWallet = async () => {
    try {
      if (typeof window.ethereum !== 'undefined') {
        setWallet(null);
        localStorage.removeItem('userWalletAddress')
        setConnectText("Connect Wallet")
      }
    } catch (error) {
      alert('Error disconnecting wallet:', error);
    }
  }

  useEffect(() => {
    const storedWalletAddress = getUserWalletAddress();
    if (storedWalletAddress) {
      setWallet(storedWalletAddress)
      setConnectText(truncateEthAddress(storedWalletAddress))
    }
  }, []);

  // CONNECT WALLET
  async function connectWallet() {
    // already stored address
    if(wallet) {
      setConnectText(truncateEthAddress(wallet))
    // nothing stored - grab it from the wallet
    } else {
      if (typeof window.ethereum !== 'undefined') {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          const web3 = new Web3(window.ethereum);

          const accounts = await web3.eth.getAccounts();
          const userAddress = accounts[0];

          setConnectText(truncateEthAddress(userAddress))

          // lets save it to local storage
          if (userAddress) {
            props.setAddress(userAddress)
            localStorage.setItem('userWalletAddress', userAddress);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        alert('No wallet detected. Please install MetaMask.');
      }
    }
  }

  // WALLET CHANGE
  if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length === 0) {
        disconnectWallet();
      }
    });
  }

  const toggleDisconnect = () => {

  }

  return (
    <>
    {wallet
    ?
      <div className="wallet-buttons">
        <button 
          className="btn skinny connect-wallet"
          onClick={() => {
            setDisconnect(value => !value);
          }}
        ><FontAwesomeIcon icon="link" />{walletConnectBtn}</button>
        <button 
          className={"btn skinny disconnect-wallet " 
          + (disconnect ? "display" : "")}
          onClick={disconnectWallet}
        ><FontAwesomeIcon icon="link-slash" />Disconnect</button>
      </div>
    :
      <button 
        className="btn skinny connect-wallet"
        onClick={connectWallet}
      ><FontAwesomeIcon icon="link" />{walletConnectBtn}</button>
    }
    </>
  );
}

export default ConnectButton;