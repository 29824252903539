import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/home';
import Component from './components/Component';
import { getUserWalletAddress } from './components/Utils';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faLinkSlash, faMagnifyingGlass, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons';
library.add(faLink,faLinkSlash, faMagnifyingGlass, faMobileScreenButton)

import pages from './pages';

function App() {
  const storedUserAddress = getUserWalletAddress();

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
  
        {pages.map((page) => {
          return <Route 
            key={page.path} 
            path={page.path} 
            element={<Component page={page} storedAddress={storedUserAddress} />} 
          />
        })}

      </Routes>
    </Router>
  )
}

export default App;