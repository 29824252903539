// Get the user's wallet address from local storage
export const getUserWalletAddress = () => {
  return localStorage.getItem('userWalletAddress');
};

export const fetchImage = (source,total, current,context,canvas,size,setImageURL) => {
  return new Promise(resolve => {
    let oneOffImg = null;
    let image = new Image();
    image.src = source;

      image.onload = () => {
        context.globalAlpha = 1;
        context.drawImage(image, 0, 0, size, size);
        resolve();
      } 

      setTimeout(() => {
        setImageURL(image);
      }, 500)

    image.setAttribute('crossorigin', 'anonymous');
  });
};