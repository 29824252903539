import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Photo from '../components/Photo';
import { Link } from 'react-router-dom';
import pages from '../pages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Home() {
  return (
    <div className="page-wrap home">
      <Header />
      <div className="home-inner container">
        <h1 className="home">Unlock the Art of <strong>NFTs</strong></h1>
        <p>Welcome to TokenSnap, your window into the mesmerizing world of NFT art and collectibles. <strong>Choose your collection below to get started!</strong></p>

        <div className="home-boxes">
          {pages.map((page) => {
            return (
              <Link
                key={page.path}
                to={page.path}
              >
                <Photo
                  key={page.path}
                  image={require(`../images/default/${page.component}.jpg`)}
                  imageAlt={page.name}
                />
                <span>{page.name}</span>
              </Link>
            )
          })}
        </div>

        <div className="not-here">
          <h2>Wallpapers more your thing?</h2>
          <p>Check out our RareBoy-inspired Wallpaper generators.</p>
          <Link 
            className="btn reach-out"
            to="https://wallpapers.tokensnap.xyz"
          ><FontAwesomeIcon icon="fa-solid fa-mobile-screen-button" />Lets Go!</Link>
        </div>
      </div>

      <Footer />
    </div>
  )
};

export default Home;