import React, { useState, useEffect } from 'react';
import { fetchImage } from '../components/Utils';
import axios from 'axios';

function Asset(props) {
  const [assetTraits, setAssetTraits] = useState('');
  const [error, setError] = useState('');

  const getSingleAssetTraits = {
    method: 'GET',
    url: 'https://api.opensea.io/v2/chain/ethereum/contract/'+props.contract+'/nfts/'+props.asset.identifier,
    headers: {accept: 'application/json', 'X-API-KEY': props.apiKey}
  }

  const clickAsset = (image) => { 
    let canvas = document.getElementById('imageCanvas');
    let context = canvas.getContext('2d');  

    axios
      .request(getSingleAssetTraits)
      .then(function (response) {
        let data = response.data;
        let imageURL  = data.nft.image_url;

        if(!props.wallpaper) {
          let image_resized = imageURL.replace('w=500', 'w=' + props.size);
          props.setToggle('image')
          props.setImageURL(image_resized)
          props.setPlainImage(image_resized)
          fetchImage(image_resized, 1, 0,context,canvas,props.size,props.setImageURL)
        } else {
          props.setImageURL(imageURL)
        }

        data.nft.traits.map((type) => {
          if(type.trait_type == props.trait) {
            let cleanTrait = type.value.replace(/\s+/g, '-').toLowerCase()
            props.setSavedTrait(cleanTrait)
          }
        }) // map 

      })
      .catch(function (error) {
        setError('Error getting assets from wallet. '+error);
    });
    
    // .then(() => {
    //   if (props.theTrait !== null) {
    //     fetchImage(props.theTrait, 2, 1);
    //   }
    // });
  };


  return (
    <div 
      onClick={() => clickAsset(props.image)}
      className="asset"
    >
      <img src={props.image} id={props.asset.identifier} />
    </div>
  );
}

export default Asset;